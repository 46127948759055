import React, { Component } from "react";
import "../layouts/index.css";

import mixpanel from "../lib/mixpanel/index";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SectionBox } from "../components/home";
import CallToAction from "../components/cta";
import { handleScroll } from "../lib/utils";
import RequestDemo from "../components/product/RequestDemo";

class Merchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: false,
      isRequestDemoOpen: false,
      email: "",
    };
  }

  componentDidMount() {
    mixpanel.track("HOME_PAGE_OPENED");
  }

  handleRequestDemo = (event) => {
    this.setState(
      (prevState) => ({
        isRequestDemoOpen: !prevState.isRequestDemoOpen,
      }),
      () => handleScroll(this.state.isRequestDemoOpen)
    );
  };

  render() {
    const headingClassName = "ma0 pa0 f3 f2-ns tc tl-l fw-600";
    const descriptionClassName = "f4 f3-ns ma0 mt4 mt10-ns tc tl-l fw-normal";
    const sideClassName = "col-12 col-6-l mt6 mt0-ns";
    const sideImgClassName = "section-image";
    const bodyClassName = "col-12 col-6-l mw-s-l align-self-center";
    const sectionClassName = "pt20 pb10 pt30-ns pb30-ns";
    return (
      <Layout headerClass="bg-color">
        <SEO title="Merchants | Getting paid more money, faster, securely" />
        <div className="main-content">
          {this.state.isRequestDemoOpen ? (
            <RequestDemo
              email={this.state.email}
              isOpen={this.state.isRequestDemoOpen}
              handleModal={this.handleRequestDemo}
            />
          ) : null}
          <SectionBox
            heading="Getting paid more money, faster, securely."
            headingClass="ma0 pa0 f2 f1-ns tc fw-bold"
            bodyClass="col-12 flex flex-column"
            className="section-slanted-bg-container"
          >
            <p className="f4 f3-ns ma0 mt4 mt10-ns tc fw-normal">
              Local businesses represent the backbone of America. It’s time
              there was a payments system that brought us all together.
            </p>
          </SectionBox>
          <SectionBox
            heading="Increase profits by cutting transaction fees"
            sideImg="merchants/chapter1.svg"
            sideImgClassName={sideImgClassName}
            headingClass={headingClassName}
            sideClass={`${sideClassName} justify-self-center justify-self-end-l`}
            bodyClass={`${bodyClassName}`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Loqal uses payments systems based on bank-transfers, rather than
              credit cards. This means you get paid faster, and it costs you
              less to get paid.
            </p>
          </SectionBox>
          <SectionBox
            heading="Contact free payments via QR Code"
            sideImg="merchants/chapter2.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} o2 o1-l justify-self-center justify-self-start-l`}
            bodyClass={`${bodyClassName} o1 o2-l justify-self-center justify-self-end-l`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Never pay for a POS machine again. We will share a printed copy of
              your unique QR Code. Simply place it next to your register, and
              your customers can pay you by scanning the image.
            </p>
          </SectionBox>
          <SectionBox
            heading="Automatically reward your best customer"
            sideImg="merchants/chapter3.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} justify-self-center justify-self-end-l`}
            bodyClass={`${bodyClassName}`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              80% of your revenues come from your top 20% of clients. Reward
              them with our customer-retention software, and make sure they keep
              coming back.
            </p>
          </SectionBox>
          <SectionBox
            heading="24x7 customer support."
            sideImg="merchants/chapter4.svg"
            headingClass={headingClassName}
            sideImgClassName={sideImgClassName}
            sideClass={`${sideClassName} o2 o1-l o2 o1-l justify-self-center justify-self-start-l`}
            bodyClass={`${bodyClassName} o1 o2-l justify-self-center justify-self-end-l`}
            className={sectionClassName}
          >
            <p className={descriptionClassName}>
              Call us or leave a message anytime you have a query.
            </p>
          </SectionBox>
          <CallToAction onButtonClick={this.handleRequestDemo} />
        </div>
      </Layout>
    );
  }
}
export default Merchants;
